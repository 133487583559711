import { useTranslation } from "@opendash/i18n";
import { Descriptions } from "antd";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { formatAddress, formatDate } from "../helper/formatter";
import { Facility, OrderFacilityRelation } from "../parse";

export default observer(
  ({
    facility,
    orderFacilityRelation,
  }: {
    facility: Facility;
    orderFacilityRelation: OrderFacilityRelation[];
  }) => {
    const t = useTranslation();

    const contractType = useMemo(() => {
      if (orderFacilityRelation.length > 0) {
        return orderFacilityRelation.map((ofr) => ofr.contractType).join(", ");
      }

      if (facility.contractType) {
        return facility.contractType;
      }

      return "-";
    }, [orderFacilityRelation.length]);

    return (
      <>
        <Descriptions
          bordered
          size="small"
          style={{ marginBottom: 30 }}
          column={1}
        >
          <Descriptions.Item
            label={t("app:classes.Facility.fields.no")}
            children={facility.no}
          />

          <Descriptions.Item
            label={t("app:classes.OrderFacilityRelation.fields.contractType")}
            children={contractType}
          />

          <Descriptions.Item
            label={t("app:classes.Facility.fields.keyDepot")}
            children={facility.keyDepot || "Keine Angabe"}
          />

          <Descriptions.Item
            label={t("app:classes.Facility.fields.address")}
            children={formatAddress(facility, true)}
          />

          <Descriptions.Item
            label={t("app:classes.Facility.fields.lastMaintenance")}
            children={formatDate(facility.lastMaintenance) || "-"}
          />
        </Descriptions>
      </>
    );
  }
);
