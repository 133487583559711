import { Icon } from "@opendash/icons";
import { Button, Drawer, List, Space } from "antd";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import styled from "styled-components";
import {
  AddItemDrawer,
  AddItemState,
  PartsListProps,
  UpdateItemDrawer,
} from "..";
import { OrderItemRelation } from "../../../parse";

const Quantity = styled.span`
  width: 40px;
  // text-align: right;
  display: inline-block;
`;

export default observer<PartsListProps>(({ state }) => {
  // Items should not be added to parts lists currently.
  // Functionality will be commented and can be implemented if needed.
  const additemstate = useMemo(() => new AddItemState(state.orderstate), []);

  return (
    <Drawer
      title={`Stückliste für "${state.name}"`}
      placement="bottom"
      open={!!state.open}
      onClose={() => (state.open = false)}
      height="90%"
      extra={
        <Space>
          <Button children="Schließen" onClick={() => (state.open = false)} />

          <Button
            children="Alles in die Zwischenablage kopieren"
            onClick={() => state.copyPartsListToClipboard()}
          />
          <Button
            type="primary"
            disabled={state.disabled}
            children="Artikel hinzufügen"
            onClick={() => (additemstate.open = true)}
          />
        </Space>
      }
    >
      <List
        dataSource={state.parts}
        renderItem={(ir: OrderItemRelation) => {
          return (
            <List.Item>
              <Space>
                <Quantity children={state.getPartQuantity(ir)} />
                <span>{state.getPartTitle(ir)}</span>
              </Space>
              <Space>
                <Button
                  disabled={state.disabled}
                  onClick={() => state.setUpdateDialog(ir)}
                >
                  <Icon icon="fa:pencil" />
                </Button>
                <Button
                  disabled={state.disabled}
                  onClick={() => state.copyPartToClipboard(ir)}
                >
                  <Icon icon="fa:copy" />
                </Button>
              </Space>
            </List.Item>
          );
        }}
      />

      <AddItemDrawer state={additemstate} />

      <UpdateItemDrawer state={state.updateitemstate} />
    </Drawer>
  );
});
