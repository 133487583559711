import Parse from "parse";

import type { Facility } from "./Facility";
import type { Order } from "./Order";

export interface OrderFacilityRelationAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  contractType?: string;
  facility: Facility;
  order: Order;
  sqlCreatedAt?: Date;
  sqlDeleted: boolean;
  sqlRef?: string;
  sqlUpdatedAt?: Date;
}

export class OrderFacilityRelation extends Parse.Object<OrderFacilityRelationAttributes> {
  static className: string = "Heinzerling2_OrderFacilityRelation";

  constructor(data?: Partial<OrderFacilityRelationAttributes>) {
    super("Heinzerling2_OrderFacilityRelation", data as OrderFacilityRelationAttributes);
  }

  get contractType(): string | undefined {
    return super.get("contractType");
  }
  set contractType(value: string | undefined) {
    super.set("contractType", value);
  }
  get facility(): Facility {
    return super.get("facility");
  }
  set facility(value: Facility) {
    super.set("facility", value);
  }
  get order(): Order {
    return super.get("order");
  }
  set order(value: Order) {
    super.set("order", value);
  }
  get sqlCreatedAt(): Date | undefined {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value: Date | undefined) {
    super.set("sqlCreatedAt", value);
  }
  get sqlDeleted(): boolean {
    return super.get("sqlDeleted");
  }
  set sqlDeleted(value: boolean) {
    super.set("sqlDeleted", value);
  }
  get sqlRef(): string | undefined {
    return super.get("sqlRef");
  }
  set sqlRef(value: string | undefined) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt(): Date | undefined {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value: Date | undefined) {
    super.set("sqlUpdatedAt", value);
  }
}

Parse.Object.registerSubclass("Heinzerling2_OrderFacilityRelation", OrderFacilityRelation);
