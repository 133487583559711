import { AdminLayout, useMediaQuery, useTranslation } from "@opendash/core";
import { useUIPermission } from "@opendash/plugin-parse";
import { AdminToolbar } from "@opendash/ui";
import type { TabsProps } from "antd";
import { Checkbox, DatePicker, List, Space, Tabs } from "antd";
import { Col, Row } from "antd/lib";
import { observer } from "mobx-react-lite";
import Parse, { Role } from "parse";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { OrdersList } from "../../features/orders";
import { WeekOverviewState } from "../../features/overview";
import { formatDate } from "../../helper/formatter";
import { $heinzerling } from "../../service";

const DATE_FORMAT = "YYYY-MM-DD";

const { RangePicker } = DatePicker;
const RangePickerResponsive = styled(RangePicker)<{ isBelowWidth: boolean }>`
  width: 100%;
  .responsive-range-picker .ant-picker-panels {
    flex-direction: ${(props) => (props.isBelowWidth ? `column` : `row`)};
  }
`;

export const WeekOverviewPage = observer(() => {
  const isBelowWidth = useMediaQuery("(max-width: 675px)");
  const t = useTranslation();
  const navigate = useNavigate();
  const { loading: loadingpermission1, found: foundpermission1 } =
    useUIPermission(["can-see-other-technician-checkbox-in-overview"]);

  const [roles, setRoles] = useState<Role[]>([]);

  const state = useMemo(() => new WeekOverviewState(), []);

  const { startParam, endParam } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();

  const tabitems: TabsProps["items"] = [
    {
      key: "orders",
      label: t("app:overview.auftrag_tab"),
      children: (
        <OrdersList
          rows={state.orders}
          loading={state.loading}
          hideElevatorLink={true}
        />
      ),
    },
    {
      key: "item",
      label: t("app:overview.artikel_tab"),
      children: (
        <List
          itemLayout="vertical"
          loading={state.loading}
          dataSource={state.items}
          rowKey={(row) => row.key}
          locale={{
            emptyText: t("app:articles.list_empty"),
          }}
          renderItem={(row) => {
            return (
              <List.Item
                extra={
                  <span style={{ fontWeight: "bold", marginLeft: 10 }}>
                    {row.quantity}
                  </span>
                }
              >
                <List.Item.Meta
                  title={row.title}
                  description={row.description}
                />

                <div
                  dangerouslySetInnerHTML={{
                    __html: row.body,
                  }}
                />
              </List.Item>
            );
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    state.setParams(
      startParam,
      endParam,
      searchParams.get("showFinished") === "true",
      searchParams.get("showOpen") === "true",
      searchParams.get("showOwnOrdersOnly") === "true",
      searchParams.get("groupOrdersByNo") === "true"
    );
  }, [
    startParam,
    endParam,
    searchParams.get("showFinished"),
    searchParams.get("showOpen"),
    searchParams.get("showOwnOrdersOnly"),
    searchParams.get("groupOrdersByNo"),
  ]);

  useEffect(() => {
    $heinzerling.sync.getUser().then((user) => {
      if (typeof user !== "undefined" && user !== null) {
        $heinzerling.sync
          .createQuery(Parse.Role)
          .equalTo("users", user)
          .find()
          .then((roles) => {
            setRoles(roles);
          });
      }
    });
    const start = state.start.format(DATE_FORMAT);
    const end = state.end.format(DATE_FORMAT);
    const qs = "".concat(
      ...[
        `showFinished=${state.showFinished}`,
        "&",
        `showOpen=${state.showOpen}`,
        "&",
        `showOwnOrdersOnly=${state.showOwnOrdersOnly}`,
        "&",
        `groupOrdersByNo=${state.groupOrdersByNo}`,
      ]
    );

    navigate(`/overview/${start}/${end}?${qs}`, {
      replace: true,
    });
  }, [
    state.start.valueOf(),
    state.end.valueOf(),
    state.showFinished,
    state.showOpen,
    state.showOwnOrdersOnly,
    state.groupOrdersByNo,
  ]);

  // React.useEffect(() => {
  //   console.log(orders.result, item.result);
  // }, [orders.result, item.result]);

  return (
    <AdminLayout contentPadding>
      <AdminToolbar
        title={t("app:overview.title")}
        description={t("app:overview.time_range", {
          start: formatDate(state.start.toDate()),
          end: formatDate(state.end.toDate()),
        })}
        // search={searchString}
        // onSearch={setSearchString}
        children={
          <Space direction="vertical" size="large" style={{ display: "flex" }}>
            <div>
              <RangePickerResponsive
                isBelowWidth={isBelowWidth}
                popupClassName="responsive-range-picker"
                getPopupContainer={(trigger) => {
                  return trigger;
                }}
                value={[state.start, state.end]}
                onChange={(value) => {
                  if (value) {
                    const [start, end] = value;

                    if (start && end) {
                      state.setTimerange(start, end);
                    }
                  }
                }}
              />
            </div>

            <Row gutter={[8, 8]}>
              <Col>
                <Checkbox
                  children={t("app:overview.show_open")}
                  checked={state.showOpen}
                  onChange={(e) => {
                    state.setShowOpen(e.target.checked);
                  }}
                />
              </Col>
              <Col>
                <Checkbox
                  children={t("app:overview.show_finished")}
                  checked={state.showFinished}
                  onChange={(e) => {
                    state.setShowFinished(e.target.checked);
                  }}
                />
              </Col>
              <Col>
                <Checkbox
                  children={t("app:overview.group_Orders")}
                  checked={state.groupOrdersByNo}
                  onChange={(e) => {
                    state.setGroupOrdersByNo(e.target.checked);
                  }}
                />
              </Col>
              {foundpermission1 ? (
                <Col>
                  <Checkbox
                    children={t("app:overview.show_OwnOrdersOnly")}
                    checked={state.showOwnOrdersOnly}
                    onChange={(e) => {
                      state.setShowOwnOrdersOnly(e.target.checked);
                    }}
                  />
                </Col>
              ) : null}
            </Row>
          </Space>
        }
      />

      <Tabs
        activeKey={state.tab}
        onChange={(tab) => state.setTab(tab)}
        items={tabitems}
      />
    </AdminLayout>
  );
});
