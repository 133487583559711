import { $framework } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Button } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { SignatureCanvasType } from "..";

export default observer<SignatureCanvasType>(({ value, onChange, state }) => {
  const canvas = useRef<HTMLCanvasElement>(null);
  const cropCanvas = useRef<HTMLCanvasElement>(null);
  let canvasbox: DOMRect;
  let ctx: CanvasRenderingContext2D;
  let cropctx: CanvasRenderingContext2D;
  let flag = false;
  let prevX = 0;
  let currX = 0;
  let prevY = 0;
  let currY = 0;
  let dot_flag: boolean = false;

  function change() {
    if (canvas.current === null || canvas.current === undefined) {
      return;
    }
    let dataURL = cropImageFromCanvas();
    if (typeof onChange === "function" && dataURL) {
      onChange(dataURL);
    }
  }

  function draw() {
    // ctx = canvas.current?.getContext("2d")!;
    ctx.beginPath();
    ctx.moveTo(prevX, prevY);
    ctx.lineTo(currX, currY);
    ctx.strokeStyle = "black";
    ctx.lineWidth = 2;
    ctx.stroke();
    ctx.closePath();
    change();
  }

  function erase() {
    ctx = canvas.current?.getContext("2d", { willReadFrequently: true })!;
    ctx?.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    cropctx = cropCanvas.current?.getContext("2d")!;
    cropctx?.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    if (typeof onChange === "function") {
      onChange("");
    }
  }

  function getMousePosition(res: string, e: MouseEvent) {
    ctx = canvas.current?.getContext("2d", { willReadFrequently: true })!;
    if (res == "down") {
      prevX = currX;
      prevY = currY;
      currX = e.clientX - (canvasbox?.left ? canvasbox?.left : 0);
      currY = e.clientY - (canvasbox?.top ? canvasbox?.top : 0);

      flag = true;
      dot_flag = true;
      if (dot_flag) {
        ctx.beginPath();
        ctx.fillStyle = "black";
        ctx.fillRect(currX, currY, 2, 2);
        ctx.closePath();
        dot_flag = false;
      }
    }
    if (res == "up" || res == "out") {
      flag = false;
    }
    if (res == "move") {
      if (flag) {
        prevX = currX;
        prevY = currY;
        currX = e.clientX - (canvasbox?.left ? canvasbox?.left : 0);
        currY = e.clientY - (canvasbox?.top ? canvasbox?.top : 0);
        draw();
      }
    }
  }

  function drawOrigin() {
    // Draw origin
    ctx.fillStyle = "red";
    ctx.fillRect(0, 0, 5, 5);
    // Draw border
    ctx.lineWidth = 2;
    ctx.strokeStyle = "blue";
    ctx.strokeRect(0, 0, canvasbox.width, canvasbox.height);
  }

  function cropImageFromCanvas() {
    let w = canvas.current!.width;
    let h = canvas.current!.height;
    let pix = { x: [] as number[], y: [] as number[] };
    let imageData = ctx.getImageData(
      0,
      0,
      canvas.current!.width,
      canvas.current!.height
    );
    let x;
    let y;
    let index;

    for (y = 0; y < h; y++) {
      for (x = 0; x < w; x++) {
        index = (y * w + x) * 4;
        if (imageData.data[index + 3] > 0) {
          pix.x.push(x);
          pix.y.push(y);
        }
      }
    }
    pix.x.sort(function (a, b) {
      return a - b;
    });
    pix.y.sort(function (a, b) {
      return a - b;
    });
    let n = pix.x.length - 1;

    w = 1 + pix.x[n] - pix.x[0];
    h = 1 + pix.y[n] - pix.y[0];
    //Check if w and h are not NaN and pix.x and pix.y are not empty
    if (isNaN(w) || isNaN(h) || pix.x.length === 0 || pix.y.length === 0) {
      return;
    }
    let cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);

    cropctx.canvas.width = w;
    cropctx.canvas.height = h;
    cropctx.putImageData(cut, 0, 0);

    return cropctx.canvas.toDataURL();
  }

  useEffect(() => {
    if (
      !canvas.current ||
      !canvas.current.getContext("2d", { willReadFrequently: true }) ||
      !canvas.current.getBoundingClientRect()
    ) {
      return;
    }

    canvasbox = canvas.current?.getBoundingClientRect()!;
    ctx = canvas.current?.getContext("2d", { willReadFrequently: true })!;
    ctx.canvas.height = canvasbox.bottom - canvasbox.top;
    ctx.canvas.width = canvasbox.right - canvasbox.left;

    cropctx = cropCanvas.current?.getContext("2d")!;
    cropctx.canvas.height = canvasbox.bottom - canvasbox.top;
    cropctx.canvas.width = canvasbox.right - canvasbox.left;

    // Set an image if one is provided
    if (value) {
      let image = new Image();
      image.onload = function () {
        ctx.drawImage(image, 0, 0);
      };
      image.src = value;
    }

    canvas.current.addEventListener(
      "mousemove",
      function (e: MouseEvent) {
        getMousePosition("move", e);
      },
      false
    );
    canvas.current.addEventListener(
      "mousedown",
      function (e: MouseEvent) {
        getMousePosition("down", e);
      },
      false
    );
    canvas.current.addEventListener(
      "mouseup",
      function (e: MouseEvent) {
        getMousePosition("up", e);
      },
      false
    );
    canvas.current.addEventListener(
      "mouseout",
      function (e: MouseEvent) {
        getMousePosition("out", e);
      },
      false
    );
    canvas.current.addEventListener(
      "touchstart",
      function (e: TouchEvent) {
        e.preventDefault();
        e.stopPropagation();
        var touch = e.touches[0];
        var mouseEvent = new MouseEvent("mousedown", {
          clientX: touch.clientX,
          clientY: touch.clientY,
        });
        getMousePosition("down", mouseEvent);
      },
      false
    );
    canvas.current.addEventListener(
      "touchend",
      function (e: TouchEvent) {
        e.preventDefault();
        e.stopPropagation();
        if (e.touches.length > 0) {
          var touch = e.touches[0];
          var mouseEvent = new MouseEvent("mouseup", {
            clientX: touch.clientX,
            clientY: touch.clientY,
          });
          getMousePosition("up", mouseEvent);
        }
      },
      false
    );
    canvas.current.addEventListener(
      "touchmove",
      function (e: TouchEvent) {
        e.preventDefault();
        e.stopPropagation();
        var touch = e.touches[0];
        var mouseEvent = new MouseEvent("mousemove", {
          clientX: touch.clientX,
          clientY: touch.clientY,
        });
        getMousePosition("move", mouseEvent);
      },
      false
    );
  }, []);

  useEffect(() => {
    screen.orientation.addEventListener("change", () => {
      // Do nothing if the step is finish
      if (state.step.key === "finish") return;

      //Delete the canvas content when rotated
      erase();

      // Timeout is needed so that elements are updated
      setTimeout(() => {
        canvasbox = canvas.current?.getBoundingClientRect()!;

        ctx.canvas.height = canvasbox.height;
        ctx.canvas.width = canvasbox.width;
        canvas.current!.height = canvasbox.height;
        canvas.current!.width = canvasbox.width;

        cropctx.canvas.height = canvasbox.height;
        cropctx.canvas.width = canvasbox.width;
        cropCanvas.current!.height = canvasbox.height;
        cropCanvas.current!.width = canvasbox.width;

        $framework.antd_msg.info("Aktuelle Unterschrift gelöscht");
      }, 500);
    });

    return () => {
      screen.orientation.removeEventListener("change", () => {});
    };
  }, [state.step.key]);

  return (
    <div
      style={{
        position: "relative",
        border: "1px solid grey",
        height: "100%",
        width: "100%",
      }}
    >
      <canvas
        ref={canvas}
        style={{
          height: "100%",
          width: "100%",
        }}
      />
      <canvas
        ref={cropCanvas}
        style={{
          height: "100%",
          width: "100%",
          display: "none",
        }}
      />

      <Button
        danger
        type="primary"
        size="large"
        shape="circle"
        onClick={() => {
          erase();
        }}
        style={{
          position: "absolute",
          bottom: 10,
          right: 10,
        }}
        icon={<Icon icon="fa:eraser" />}
      />
    </div>
  );
});
